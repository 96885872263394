<template>
  <custom-dialog :dialog="dialog" @outsideClick="$emit('update:dialog', false)">
    <DialogTitle :title="$vuetify.lang.t('$vuetify.complaintRecord')" />

    <v-card-text class="pb-0">
      <v-form v-model="valid" ref="form" lazy-validation>
        <v-container fluid>
          <!-- Order Number -->
          <v-row>
            <v-col class="py-0" cols="12">
              <v-text-field
                style="color: black"
                :label="$vuetify.lang.t('$vuetify.order')"
                v-model="myObj.order_po"
                :append-icon="'mdi-account-search'"
                @click="handleDialogOrder"
                @click:append="handleDialogOrder"
                :disabled="!isBtnSearchOrder"
                :rules="[rules.required]"
                required
                readonly
              />
            </v-col>
          </v-row>

          <v-row v-if="SelectedOrder.products != undefined">
            <v-col cols="12">
              <v-data-table
                :headers="getHeaders"
                :items="SelectedOrder.products"
                class="elevation-4"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                        @click="handleDeleteDialog(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <span>{{ $vuetify.lang.t("$vuetify.delete") }}</span>
                  </v-tooltip>
                </template>
              </v-data-table>

              <DialogDelete
                :dialogDelete.sync="dialogDelete"
                :deleteObj="deleteObj"
                @handleDelete="handleDeleteConfirm"
              />
            </v-col>
          </v-row>

          <v-row>
            <!-- Problem Description -->
            <v-col class="pb-0" cols="12" lg="12" md="12" sm="12">
              <v-textarea
                :label="$vuetify.lang.t('$vuetify.problemDesc')"
                outlined
                v-model="myObj.description"
              />
            </v-col>

            <!-- Date of Complain -->
            <v-col class="py-0" cols="12" lg="6" md="6" sm="6">
              <v-menu
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="auto"
                class="ma-0 pa-0"
                v-model="menu"
              >
                <template v-slot:activator="{ on, attrs }" class="ma-0 pa-0">
                  <v-text-field
                    v-model="myObj.complaint_date"
                    prepend-inner-icon="mdi-calendar"
                    :label="$vuetify.lang.t('$vuetify.complaintDate')"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>

                <v-date-picker
                  v-model="myObj.complaint_date"
                  @input="menu = false"
                  class="ma-0 pa-0"
                />
              </v-menu>
            </v-col>

            <!-- Date of Refund -->
            <v-col class="py-0" cols="12" lg="6" md="6" sm="6">
              <v-menu
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="auto"
                class="ma-0 pa-0"
                v-model="menu1"
              >
                <template v-slot:activator="{ on, attrs }" class="ma-0 pa-0">
                  <v-text-field
                    v-model="myObj.receive_date"
                    prepend-inner-icon="mdi-calendar"
                    :label="$vuetify.lang.t('$vuetify.dateOfReceipt')"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>

                <v-date-picker
                  v-model="myObj.receive_date"
                  @input="menu1 = false"
                  class="ma-0 pa-0"
                />
              </v-menu>
            </v-col>

            <!-- Whether to Reply Customer -->
            <v-col class="py-0" cols="12" lg="12" md="6" sm="6">
              <p class="font-weight-bold display-1 mt-2">
                {{ $vuetify.lang.t("$vuetify.whetherRestoreCustomer") }}
              </p>

              <v-radio-group row v-model="myObj.is_reply" mandatory>
                <v-radio label="No" :value="false" />
                <v-radio label="Yes" :value="true" />
              </v-radio-group>
            </v-col>

            <!-- Handling Method -->
            <v-col class="py-0" cols="12" lg="12" md="12" sm="12">
              <v-textarea
                :label="$vuetify.lang.t('$vuetify.handlingMethod')"
                outlined
                v-model="myObj.do_way"
              />
            </v-col>

            <!-- Whether to close Case -->
            <v-col class="py-0" cols="12" lg="12" md="6" sm="6">
              <p class="font-weight-bold display-1 mt-2">
                {{ $vuetify.lang.t("$vuetify.whetherCloseCase") }}
              </p>

              <v-radio-group row v-model="myObj.status" mandatory>
                <v-radio label="No" :value="0" />
                <v-radio label="Yes" :value="1" />
              </v-radio-group>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions class="pb-8">
      <v-spacer />

      <v-btn color="red darken-2" outlined @click="cancelForm">
        {{ $vuetify.lang.t("$vuetify.cancel") }}
      </v-btn>

      <v-btn color="green darken-2" @click="validate">
        {{ $vuetify.lang.t("$vuetify.save") }}
      </v-btn>

      <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
    </v-card-actions>
  </custom-dialog>
</template>

<script>
export default {
  components: {
    DialogDelete: () => import("@/components/deleteDialog"),
  },
  props: {
    dialog: Boolean,
    myObj: Object,
    orderId: String,
    SelectedOrder: Object,
    isBtnSearchOrder: Boolean,
  },
  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      date1: new Date().toISOString().substr(0, 10),
      menu: false,
      menu1: false,
      valid: false,
      action: false,
      dialogDelete: false,
      deleteObj: {},
      rules: {
        required: (value) =>
          !!value || this.$vuetify.lang.t("$vuetify.required"),
      },
      headersWithAction: [
        {
          text: this.$vuetify.lang.t("$vuetify.complaintGoods"),
          value: "nick_name",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.unitPrice"),
          value: "price",
          width: "120",
          align: "center",
          sortable: false,
        },
        {
          text: "Number of Complaint",
          value: "number",
          width: "160",
          align: "center",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.opt"),
          value: "actions",
          sortable: false,
          align: "center",
          width: "120",
        },
      ],
      headersWithoutAction: [
        {
          text: this.$vuetify.lang.t("$vuetify.complaintGoods"),
          value: "nick_name",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.unitPrice"),
          value: "price",
          width: "120",
          align: "center",
          sortable: false,
        },
        {
          text: "Number of Complaint",
          value: "number",
          width: "160",
          align: "center",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    cancelForm() {
      this.$emit("update:dialog", false);
    },
    reset() {
      this.$refs.form.reset();
    },
    handleDialogOrder() {
      this.$emit("selectOrder");
    },
    validate() {
      this.$refs.form.validate() == true
        ? this.$emit("handleData", this.myObj)
        : console.log("false");
    },
    handleDeleteDialog(obj) {
      this.dialogDelete = true;
      this.deleteObj = obj;
    },
    handleDeleteConfirm(index) {
      this.dialogDelete = false;

      this.SelectedOrder.products.splice(
        this.SelectedOrder.products.indexOf(index),
        1
      );
    },
  },
  watch: {
    SelectedOrder() {
      if (this.SelectedOrder != undefined) {
        if (this.SelectedOrder.products != undefined) {
          if (this.SelectedOrder.products.length > 1) {
            this.action = true;
          } else {
            this.action = false;
          }
        }
      }
    },
  },
  computed: {
    getHeaders: function () {
      if (this.action == true) {
        return [
          {
            text: this.$vuetify.lang.t("$vuetify.complaintGoods"),
            value: "nick_name",
            sortable: false,
          },
          {
            text: this.$vuetify.lang.t("$vuetify.unitPrice"),
            value: "price",
            width: "120",
            align: "center",
            sortable: false,
          },
          {
            text: "Number of Complaint",
            value: "number",
            width: "160",
            align: "center",
            sortable: false,
          },
          {
            text: this.$vuetify.lang.t("$vuetify.opt"),
            value: "actions",
            sortable: false,
            align: "center",
            width: "120",
          },
        ];
      } else {
        return [
          {
            text: this.$vuetify.lang.t("$vuetify.complaintGoods"),
            value: "nick_name",
            sortable: false,
          },
          {
            text: this.$vuetify.lang.t("$vuetify.unitPrice"),
            value: "price",
            width: "120",
            align: "center",
            sortable: false,
          },
          {
            text: "Number of Complaint",
            value: "number",
            width: "160",
            align: "center",
            sortable: false,
          },
        ];
      }
    },
  },
};
</script>